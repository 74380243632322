<template>
    <div>
        <starter-navbar :colorOnScroll="400"></starter-navbar>
        <b-container>
          <section class="topSection">
              <header>
                <h3 id="stickyTitle" style=" background-color:#f8f9fa; z-index:1000" class="fixed-top">
                  <b-container style="padding-top:0.25em; padding-bottom:0.25em">
                      <b-row align-v="center" style="margin-right:0; margin-left:0;">
                          <b-col  md="3"></b-col>
                          <b-col md="6" style="text-align:center;">
                              {{$t("myFavs")}}
                          </b-col>
                          <b-col md="3" :style="!onDesktop ? 'text-align:center' : 'text-align:right;'">
                              <n-button type="primary" style="padding:0.25em" @click="deleteAllDialog()"><b-icon-x-octagon style="padding-right:0.2em"></b-icon-x-octagon>{{$t("empty")}}</n-button>
                          </b-col>
                      </b-row>
                  <!--<n-button type="primary" style="padding:0.25em" @click="nextStep()" v-if="this.pivotReady"><b-icon-arrow-right :alt="$t('fw')"></b-icon-arrow-right></n-button>-->
                  </b-container>
                </h3>
              </header>
              <b-card 
                  class="overflow-hidden b-card h-100"
                  v-if="this.$store.getters.getFavourites.length === 0"
              >
                  <b-card-body class="centeredText">
                      {{$t('noFav')}}
                  </b-card-body>
              </b-card>
              <b-container fluid v-else>
                  <CardList
                      list-title="Preferiti"
                      :elems="this.$store.getters.getFavourites" 
                      :handler="(el)=>{}"
                      :thumbnail="this.thumbnailSrc"
                      :alt="this.alt"
                      :title="this.cardTitle"
                      :body="this.cardBody"
                      :alwaysOnDesktop="true"
                      :actions="[{'text':$t('vai'),'handle':seeDetails},{'text':$t('cancella'),'handle':function(el,index){$store.commit('removeFavouriteByIndex',index)}}]"
                      :pointerChange="false"
                  />
              </b-container>
          </section>
        </b-container>
        <starter-footer :backgroundColor="'black'"></starter-footer>
    </div>
</template>

<script>
import StarterFooter from '../layout/StarterFooter.vue';
import StarterNavbar from '../layout/StarterNavbar.vue';
import {Button} from '@/components'
import CardList from './components/CardList.vue'


const Common = require('@/Common.vue').default
import Swal from 'sweetalert2'
export default {
  components: { StarterNavbar, StarterFooter, [Button.name]: Button, CardList },
  mounted : function(){
      var offsetHeight = document.getElementById('mainNav').offsetHeight;
      document.getElementById('stickyTitle').style.marginTop = offsetHeight + "px"
      window.setTimeout(() => {
          var all = document.getElementsByClassName('b-card');
          var offsetHeight = document.getElementById('stickyTitle').offsetHeight;
          all[0].style.marginTop = offsetHeight + "px";
          /*for (var i = 0; i < all.length; i++) {
              all[i].style.marginTop = offsetHeight + "px";
          }*/
      },500)
  },
  methods: {
      show_info: function(classType,title,text){
          Swal.fire({
            icon: classType,
            title: title,
            text: text,
            confirmButtonColor: '#f96332'
          })
      },
      deleteAllDialog: function(){
          var self = this;
          Swal.fire({
                title: self.$t("confermi"),
                icon: 'question',
                text: self.$t("emptyFav"),
                showDenyButton: true,
                confirmButtonText: self.$t("conferma"),
                denyButtonText: self.$t("annulla"),
                confirmButtonColor: '#FF3636',
                denyButtonColor: '#979797'
            }).then((result) => {
                if (result.isConfirmed) {
                    self.clear_fav()
                    window.setTimeout(() => {
                    var all = document.getElementsByClassName('b-card');
                    var offsetHeight = document.getElementById('stickyTitle').offsetHeight;
                    all[0].style.marginTop = offsetHeight + "px";
                    /*for (var i = 0; i < all.length; i++) {
                        all[i].style.marginTop = offsetHeight + "px";
                    }*/
                    },500)
                    Swal.fire({
                        title: self.$t("fatto"),
                        icon: 'success',
                        confirmButtonColor: '#f96332'
                    })
                }
          })
      },
      clear_fav: function(){
          var self = this
          this.$store.commit('clearFavourites');
          this.show_info("success",self.$t("deletedAll"),5000);
      },
      destination: function(link){
        if(link.includes("maglie-pivot"))
            return {'name':"magliefrompivot",params:{pivot:link.split("/").pop()}}
        else
            return {name: 'percorso', params: {percorso: link.split("/").pop()}}
      },
      seeDetails : function(m){
        this.$router.push({path : m.link})
      },
      thumbnailSrc: function(elem){return elem.img},
      alt: function(elem){return this.$t('imageFor') +elem.title },
      cardTitle: function(elem){return elem.title},
      cardBody: function(elem){return ''},
  },
  computed: {
      onDesktop: () => window.innerWidth >= 768,
      favs: function(){
        return this.$store.getters.getFavourites
      }
  },
  watch: {
    favs (n, o) {
      if(n.length === 0){
        window.setTimeout(() => {
          var all = document.getElementsByClassName('b-card');
          var offsetHeight = document.getElementById('stickyTitle').offsetHeight;
          all[0].style.marginTop = offsetHeight + "px";
          /*for (var i = 0; i < all.length; i++) {
              all[i].style.marginTop = offsetHeight + "px";
          }*/
        },500)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.topSection{
  margin-top: 90px;
}
.centeredText{
    text-align:center;
}
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@include media-breakpoint-up(md) {
  .b-card{
    margin-left:5%;
    max-width:90%;
  }
}
.cardImg{
  //max-width:100px;
  object-fit:cover;
  height:100%; 
}
.cardBody{
  padding-top:0
}
.b-card{
  margin-bottom:2%
}
.align {
  position:absolute;
  top:50%; 
  transform: translateY(-50%); 
}
h3{
  text-align:center;
}
</style>

